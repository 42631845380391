import React from "react";
import { State } from "./index.interface";
//import { img_url } from "../../assets/js/global";
import styles from "./index.module.less";
import qs from "qs";
import Header from "../../components/Header";
import { getDetail, getText } from "../../api/server";
import ReactPlayer from "react-player";
import Panzoom from "panzoom";

import word from "../..//assets/img/file_type_icons/w@2x.png";
import txt from "../..//assets/img/file_type_icons/T@2x.png";
import pdf from "../..//assets/img/file_type_icons/P@2x.png";
import xl from "../..//assets/img/file_type_icons/x@2x.png";
import wz from "../..//assets/img/file_type_icons/wh@2x.png";
import sd from "../..//assets/img/file_type_icons/3D.png";
class News extends React.Component<PageProps, State> {
  constructor(props: PageProps) {
    super(props);
    this.state = {
      code: "",
      id: "",
      index: 0,
      url: "",
      show: true,
      detail: {
        title: "",
        pointList: [],
        introduction: "",
        code: "",
        startTime: "",
        endTime: "",
        action: [],
        video: [],
        questionnaires: [],
        isFree: true,
      },
      file: { name: "", status: "", url: "", type: "" },
    };
  }

  componentDidMount() {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    this.setState(
      {
        code: params.code + "",
        id: params.id + "",
        index: params.index ? parseInt(params.index) : 0,
      },
      () => {
        this.getDataDetail(params.code);
      }
    );
  }
  componentWillReceiveProps(newProps: any) {
    const params = qs.parse(newProps.location.search, {
      ignoreQueryPrefix: true,
    });
    this.setState(
      {
        index: params.index,
      },
      () => {
        this.getDataDetail(params.code);
      }
    );
    // 一些操作
  }
  getDataDetail = async (code: string) => {
    const { id, index } = this.state;
    let res: any = {};
    res = await getDetail(code);
    if (res.code === "OK") {
      let detail = res.data.pointList.filter((dd: any) => dd.id == id);
      if (detail.length == 0) {
        this.setState({
          show: false,
        });
        return;
      }
      detail = detail[0];
      detail.image = detail.image ? JSON.parse(detail.image) : "";
      detail.file = detail.file ? JSON.parse(detail.file) : [];
      console.log(detail);

      let item = detail.file[index];
      let url = item.url;
      let type = "";
      if (!url) return;
      if (url.indexOf(".mp4") >= 0) {
        type = "video";
      } else if (
        url.indexOf(".jpg") >= 0 ||
        url.indexOf(".png") >= 0 ||
        url.indexOf(".webp") >= 0 ||
        url.indexOf(".gif") >= 0
      ) {
        type = "img";
      } else if (
        url.indexOf(".doc") >= 0 ||
        url.indexOf(".docx") >= 0 ||
        url.indexOf(".webp") >= 0 ||
        url.indexOf(".xls") >= 0 ||
        url.indexOf(".xlsx") >= 0 ||
        url.indexOf(".ppt") >= 0 ||
        url.indexOf(".pptx") >= 0
      ) {
        type = "office";
      } else if (url.indexOf(".txt") >= 0) {
        type = "text";
      } else {
        type = "url";
      }
      item.type = type;
      item.file = item.file ? item.file : item.url;
      item.title = item.title ? item.title : item.name;
      if (item.file.indexOf("http") == -1) {
        item.file = "https://www.3dbody.com" + item.file;
      }

      if (item.type == "office") {
        location.href =
          "https://view.officeapps.live.com/op/view.aspx?src=" + item.file;

        return;
      }
      if (item.type == "text") {
        let res = await getText(item.file);

        item.content = JSON.stringify(res);
      }

      this.setState(
        {
          detail: detail,
          file: item,
          show: true,
        },
        () => {
          const elem = document.getElementById("img");
          if (elem) {
            Panzoom(elem, {
              maxZoom: 2,
              smoothScroll: false,
              minZoom: 0.5,
              zoomSpeed: 0.065,
              pinchSpeed: 2, // 比手指之间的距离快两倍,
              //transformOrigin: { x: 0.5, y: 0.5 },
            });
          }
        }
      );
    } else {
      this.setState({
        show: false,
      });
    }
  };
  changeFile(index: number) {
    const { history } = this.props;
    const { code, id } = this.state;
    history.replace(`/ercode_view_iframe?code=${code}&id=${id}&index=${index}`);
  }

  getTumb(url: any) {
    if (url.indexOf(".mp4") > 0) {
      if (url.indexOf("cc.") != -1) {
        return url + "?vframe/jpg/offset/1/w/100/h/100";
      }

      return (
        url + "?x-oss-process=video/snapshot,t_1000,f_jpg,w_100,h_100,m_fast"
      );
    } else if (
      url.indexOf(".jpg") > 0 ||
      url.indexOf(".png") > 0 ||
      url.indexOf(".webp") > 0 ||
      url.indexOf(".gif") > 0
    ) {
      // return url + '?imageView2/1/w/100/h/100/format/jpg'
      return url + "?x-oss-process=image/resize,w_100/format,jpg";
    } else if (url.indexOf(".docx") > 0 || url.indexOf(".doc") > 0) {
      return word;
    } else if (url.indexOf(".xlsx") > 0 || url.indexOf(".xls") > 0) {
      return xl;
    } else if (url.indexOf(".pdf") > 0) {
      return pdf;
    } else if (url.indexOf(".txt") > 0) {
      return txt;
    } else if (url.indexOf("#") > 0 || url.indexOf("html5app") > 0) {
      return sd;
    } else {
      return wz;
    }
  }
  handleResize = (e: any) => {
    let e_width = e.target.innerWidth;
    this.handleClientW(e_width);
  };
  handleClientW = (width: any) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    let url = params.path;
    if (!url) {
      return;
    }
    if (
      url.indexOf(".jpg") >= 0 ||
      url.indexOf(".png") >= 0 ||
      url.indexOf(".webp") >= 0 ||
      url.indexOf(".gif") >= 0
    ) {
      url += "?imageView2/2/w/" + width + "/format/jpg";
    }

    this.setState({ url: url });
    var newFrame = document.createElement("iframe");
    newFrame.src = url;
    var parent = document.getElementById("parentDom");
    if (parent) {
      parent.innerHTML = "";
      parent.appendChild(newFrame);
    }
  };
  handleContextMenu = (event: any) => {
    event.preventDefault();
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize.bind(this));
  }
  render() {
    const { detail, file, index } = this.state;
    return (
      <div className={styles.container}>
        <Header
          type={1}
          history={history}
          title={detail.isMirror ? detail.title + "(镜像)" : detail.title}
        ></Header>
        <div className={styles.modelBox}>
          <div
            className={styles.sourceBox}
            onContextMenu={this.handleContextMenu}
          >
            {file.type == "video" ? (
              <div style={{ width: "100%", background: "black" }}>
                <div
                  style={{
                    width: "100%",
                    paddingTop: "calc(60vh - 100px)",
                    position: "relative",
                    marginTop: "0px",
                  }}
                >
                  <ReactPlayer
                    className={
                      "react-player " +
                      (detail.isMirror && detail.type == 1
                        ? styles.ismirro
                        : "")
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      padding: "2px",
                    }}
                    //这里是由上级页面传过来的视频地址
                    url={file.file}
                    playing={true}
                    width="100%"
                    height="100%"
                    controls={detail.type == 1 ? false : true}
                    controlsList="nodownload"
                    loop
                    pip
                    disablePictureInPicture
                    webkit-playsinline="true"
                    x5-video-player-type="h5-page"
                    playsinline={true}
                  />
                </div>

                {/* 								<video src={file.file} style={{ width: '100%', marginTop: '45px' }} controls autoPlay controlsList="nodownload" disablePictureInPicture webkit-playsinline playsinline x5-video-player-type x5-playsinline="h5-page"></video>
                 */}
              </div>
            ) : (
              ""
            )}
            {file.type == "img" ? (
              <div className={styles.imgbox}>
                <div
                  id="img"
                  style={{ width: "100%", height: "100%" }}
                  className={detail.isMirror ? styles.ismirro : ""}
                >
                  <img src={file.file}></img>
                </div>
              </div>
            ) : (
              ""
            )}
            {file.type == "office" ? (
              <iframe
                src={
                  "https://view.officeapps.live.com/op/view.aspx?src=" +
                  file.file
                }
              ></iframe>
            ) : (
              ""
            )}
            {file.type == "text" ? (
              <div className={styles.text}>{file.name}</div>
            ) : (
              ""
            )}
            {file.type == "url" ? <iframe src={file.file}></iframe> : ""}

            {detail.file && detail.file.length > 1 && (
              <div className={styles.pointBox}>
                <div className={styles.plist}>
                  {detail.file.map((ii: any, hh: any) => (
                    <div
                      onClick={() => this.changeFile(hh)}
                      key={ii.file}
                      className={`${styles.pItem} ${
                        hh == index ? styles.active : ""
                      }`}
                    >
                      <img
                        src={this.getTumb(ii.file)}
                        className={detail.isMirror ? styles.ismirro : ""}
                      ></img>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {detail.type == 1 && detail.frequency && (
              <div className={styles.timeBox}>
                <div className={styles.timeTitle}>锻炼时间：</div>
                <div className={styles.time}>
                  {detail.frequency.map(
                    (ii: any) =>
                      ii.isSelect && (
                        <div key={ii.id} className={styles.timeItem}>
                          {ii.title}：{ii.count}
                        </div>
                      )
                  )}
                </div>
              </div>
            )}
            {detail.introduction != "" && (
              <div className={styles.timeBox}>
                <div className={styles.timeTitle}>介绍：</div>
                <div className={styles.time}>
                  <div
                    className={styles.introduction}
                    dangerouslySetInnerHTML={{
                      __html: detail.introduction.replace(/\n/g, "<br/>"),
                    }}
                  ></div>
                </div>
              </div>
            )}
            {detail.note != "" && (
              <div className={styles.timeBox}>
                <div className={styles.timeTitle}>备注：</div>
                <div className={styles.time}>
                  <div className={styles.introduction}>{detail.note}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
    // );
  }
}

export default News;
