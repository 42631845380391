import React from "react";
import { Icon, Flex, Result } from "antd-mobile";
import { State } from "./index.interface";
//import { img_url } from "../../assets/js/global";
import styles from "./index.module.less";
//import ErcodeItem from "../../components/ErcodeItem";
import qs from "qs";
import { getDetail } from "../../api/server";
import Header from "../../components/Header";
import { formatTime } from "../../utils/common";
//import { isBuffer } from "util";

class News extends React.Component<PageProps, State> {
  constructor(props: PageProps) {
    super(props);
    this.state = {
      code: "",
      activeTab: 0,
      detail: {
        title: "",
        pointList: [],
        introduction: "",
        code: "",
        startTime: "",
        endTime: "",
        action: [],
        video: [],
        questionnaires: [],
        isFree: true,
      },
      model: false,
      source: {
        name: "",
        status: "",
        uid: "",
        url: "",
        type: "",
      },
      show: false,
      itemDetail: {},
      activeIndex: 0,
    };
  }

  componentDidMount() {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    this.setState(
      {
        code: params.code + "",
      },
      () => {
        this.getDataDetail(params.code);
      }
    );
  }
  openView = (item: any) => {
    const { code } = this.state;
    const { history } = this.props;
    history.push(`/ercode_view_iframe?code=${code}&id=${item.id}&index=0`);
  };
  handleToSoftDetail = async (index: any) => {
    const { itemDetail } = this.state;
    console.log(itemDetail);
    let item: any = itemDetail.file[index];
    const { history } = this.props;
    history.push(
      `/ercode_view_iframe?code=${item.file}&name=${item.name}&index=${item.name}`
    );

    this.setState({
      model: true,
      source: item,
    });
  };
  getDataDetail = async (code: string) => {
    let res: any = {};
    res = await getDetail(code);
    if (res.code === "OK") {
      res.data.action = [];
      res.data.video = [];
      for (var i = 0; i < res.data.pointList.length; i++) {
        res.data.pointList[i].image = res.data.pointList[i].image
          ? JSON.parse(res.data.pointList[i].image)
          : "";
        res.data.pointList[i].file = res.data.pointList[i].file
          ? JSON.parse(res.data.pointList[i].file)
          : [];
        if (res.data.pointList[i].type == "1") {
          res.data.action.push(res.data.pointList[i]);
        } else {
          res.data.video.push(res.data.pointList[i]);
        }
      }

      this.setState({
        detail: res.data,
        show: true,
      });
    } else {
      this.setState({
        show: false,
      });
    }
  };

  handleback = () => {
    this.setState({ model: false });
  };

  render() {
    const { detail, model, show } = this.state;
    /* const geturl = (file: any, item: any) => {
			if (!file.url) return ''
			if (file.url.indexOf(".mp4") >= 0) {
				return file.url + "?vframe/jpg/offset/5/w/400/h/400";
			} else if (
				file.url.indexOf(".jpg") >= 0 ||
				file.url.indexOf(".png") >= 0 ||
				file.url.indexOf(".webp") >= 0 ||
				file.url.indexOf(".gif") >= 0
			) {
				return file.url;
			} else {
				return item.image.file;
			}
		} */

    return (
      <div
        className={styles.container}
        style={{ overflow: model ? "hidden" : "auto" }}
      >
        <Header type={0} history={history} title="运动处方"></Header>

        {show && detail.isFree == true ? (
          <div className={styles.main}>
            {/* <div className={styles.title}>患教处方</div> */}
            <div className={styles.titleBox}>
              <div className={styles.titlleft}>处方编号： </div>
              <div className={styles.titleright}>{detail.code}</div>
            </div>

            <div className={styles.titleBox}>
              <div className={styles.titlleft}>标题/介绍：</div>
              <div className={styles.titleright}>{detail.title} </div>
            </div>
            <div className={styles.titleBox}>
              <div className={styles.titlleft}>开始~结束时间：</div>
              <div className={styles.titleright}>
                {" "}
                {detail.startTime && detail.endTime
                  ? formatTime(detail.startTime, "YYYY-MM-DD") +
                    " ~ " +
                    formatTime(detail.endTime, "YYYY-MM-DD")
                  : "--"}{" "}
              </div>
            </div>
            <div className={styles.titleBox}>
              <div className={styles.titlleft}>处方内容：</div>
              <div className={styles.titleright}> </div>
            </div>
            {detail.action.length > 0 && (
              <div className={styles.ercodeBox}>
                <div className={styles.subtitle}>【动作库】</div>
                <Flex wrap="wrap" justify="start" style={{ padding: "0 10px" }}>
                  {detail.action &&
                    detail.action.map((item, index) => (
                      <div className={styles.ercodeItem} key={item.id}>
                        <div className={styles.card}>
                          <div
                            key={item.id}
                            onClick={() => this.openView(item)}
                            className={styles.item}
                            style={{
                              position: "relative",
                              paddingTop: "100%",
                            }}
                          >
                            <img
                              src={
                                item.image.file
                                  ? item.image.file
                                  : item.image.url
                              }
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                transform: item.isMirror
                                  ? "rotateY(180deg)"
                                  : "rotateY(0deg)",
                              }}
                              alt=""
                            />
                            <span
                              style={{
                                position: "absolute",
                                top: "-5px",
                                left: "-5px",
                                background: "#ffdd00",
                                padding: "3px 8px",
                                borderRadius: "5px",
                                fontSize: "12px",
                                display: item.isMirror ? "unset" : "none",
                              }}
                            >
                              镜像
                            </span>
                          </div>
                          <div className={styles.ititle}>
                            {item.title}
                            {item.isMirror ? "(镜像)" : ""}
                          </div>
                        </div>
                      </div>
                    ))}
                </Flex>
              </div>
            )}
            {detail.video.length > 0 && (
              <div className={styles.ercodeBox}>
                <div className={styles.subtitle}>【患者教育】</div>
                <Flex wrap="wrap" justify="start" style={{ padding: "0 10px" }}>
                  {detail.video &&
                    detail.video.map((item, index) => (
                      <div className={styles.ercodeItem} key={item.id}>
                        <div className={styles.card}>
                          <div
                            key={item.id}
                            onClick={() => this.openView(item)}
                            className={styles.item}
                            style={{
                              backgroundImage:
                                "url(" +
                                (item.image.file
                                  ? item.image.file
                                  : item.image.url) +
                                ")",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center center",
                              backgroundSize: "cover",
                              width: "100%",
                              paddingTop: "100%",
                              display: "inline-block",
                              borderBottom: "1px solid #eee",
                              position: "relative",
                              margin: "0px",
                            }}
                          ></div>
                          <div className={styles.ititle}>{item.title}</div>
                        </div>
                      </div>
                    ))}
                </Flex>
              </div>
            )}
          </div>
        ) : detail.isFree == false ? (
          <Result
            img={
              <Icon
                type="cross-circle-o"
                className="spe"
                style={{ fill: "#F13642" }}
              />
            }
            title="预览失败"
            message="此处方为付费服务，请下载APP购买后方可查看！"
          />
        ) : show == true ? (
          <Result
            img={
              <Icon
                type="cross-circle-o"
                className="spe"
                style={{ fill: "#F13642" }}
              />
            }
            title="预览失败"
            message="此处方已失效，请联系您的患教医生！"
          />
        ) : (
          ""
        )}
      </div>
    );
    // );
  }
}

export default News;
