import React from "react";
import { Result, Icon } from "antd-mobile";
import { State } from "./index.interface";
//import { img_url } from "../../assets/js/global";
import styles from "./index.module.less";
import qs from "qs";
import { bindUser } from "../../api/server";
import Header from "../../components/Header";
import selected_icon from "../../assets/img/selected_icon.png";

class News extends React.Component<PageProps, State> {
  constructor(props: PageProps) {
    super(props);
    this.state = {
      code: "",
      mobile: "",
      userId: "",
      result: "0",
    };
  }

  componentDidMount() {
    //window.scrollTo(0, 0);
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    this.setState(
      {
        code: params.code + "",
        mobile: params.mobile + "",
        userId: params.userId + "",
      },
      () => {
        if (this.state.code && this.state.mobile && this.state.userId) {
          this.bindUser();
        } else {
          this.setState({ result: "2" });
        }
      }
    );
  }

  bindUser = async () => {
    const { code, mobile, userId } = this.state;
    let res: any = {};
    res = await bindUser({ code: code, mobile: mobile, ownerUserId: userId });
    if (res.code == "OK" || res.message == "该患者已绑定") {
      this.setState({ result: "1" });
    } else {
      this.setState({ result: "2" });
    }
  };

  render() {
    const { result } = this.state;
    const myImg = (src: any) => (
      <img src={selected_icon} className={styles.resultIcon} alt="" />
    );
    return (
      <div className={styles.container}>
        <Header type={0} history={history}></Header>
        <div className={styles.main}>
          {result === "1" ? (
            <Result
              className={styles.box}
              img={myImg("")}
              title="绑定成功"
              message={
                <div>
                  您将收到来自医生（康复师/教练）给你推荐的运动方案，请在微信搜索【第三人体】小程序进行使用。
                </div>
              }
            />
          ) : (
            ""
          )}
          {result === "2" ? (
            <Result
              className={styles.box}
              img={
                <Icon
                  type="cross-circle-o"
                  className={styles.resultIcon}
                  style={{ fill: "#F13642" }}
                />
              }
              title="绑定失败"
              message={<div>绑定失败，请联系您的康复医生！</div>}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
    // );
  }
}

export default News;
